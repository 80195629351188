import React from 'react';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'assets/logo_blue.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#Feature7_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>核心技术</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#Feature0_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>核心产品</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Content5_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>客户案例</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#Teams2_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>关于无问</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '#Footer1_0',
          children: [
            {
              children: (
                <span>
                  <p>联系我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        <span>
          <p></p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>让 AI 更简单，让创新更高效</p>
      </span>
    ),
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>核心技术</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <span>
              <p>
                无问智科应用大模型和仿真技术打造
                AI数据与仿真验证一体化解决方案，
                成为AI时代的关键基础设施和加速器
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: (
                <img src='assets/card.svg' alt='img'></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>机器标注</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    应用大模型技术实现机器代替人工标注模式，实现数据标注的新范式， 低成本、高效、高质量的数据标注模式
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: (
                <img src='assets/card.svg' alt='img'></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>数据合成</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      应用AIGC和仿真技术实现数据生成和数据合成， 打造低成本、高质量的数据供给模式， 实现数据供给新范式
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: (
                <img src='assets/card.svg' alt='img'></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>大规模仿真模拟</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    应用大模型技术和仿真技术实现集场景库和仿真能力一体化仿真测试平台，精确<span
                                    >可控、真实
                                    </span>
                                    <span>可靠、大规模场景</span>
                                    <span>构建的模拟仿真</span>
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: (
                <img src='assets/card.svg' alt='img'></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>数据与测试验证闭环</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    以数据为驱动无缝链接数据生产、机器标注、模型训练和测试验证，打造完整、高效、高自动化的数据闭环能力
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>核心产品</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img src='assets/icon1.png' alt='img'></img>
              ),  
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>AI数据标注平台</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        用大模型研发AI数据标注新范式，实现机器标注替代人工标注的模式
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img src='assets/icon2.png' alt='img'></img>
              ),  
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>仿真验证平台</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      以最佳实践研发数据驱动型仿真验证平台，大幅提升仿真准确率和数据价值
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img src='assets/icon3.png' alt='img'></img>
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>数据闭环管理平台</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      应用大模型技术打造数据驱动的AI基础设施平台，实现数据闭环价值最大化
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 16, xs: 24 },
  img: {
    children: 'assets/autolabel.jpeg',
  },
  textWrapper: { className: 'content1-text', md: 6, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>AI数据标注平台</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                用大模型研发AI数据标注新范式，实现机器标注替代人工标注的模式
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper feature2-wrapper' },
  OverPack: { className: 'home-page feature2', playScale: 0.3 },
  imgWrapper: { className: 'feature2-img', md: 16, xs: 24 },
  img: {
    children: 'assets/simulator.png',
  },
  textWrapper: { className: 'feature2-text', md: 6, xs: 24 },
  title: {
    className: 'feature2-title',
    children: (
      <span>
        <p>仿真验证平台</p>
      </span>
    ),
  },
  content: {
    className: 'feature2-content',
    children: (
      <span>
        <span>
          <p>
            以最佳实践研发数据驱动型仿真验证平台，大幅提升仿真准确率和数据价值
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper feature1-wrapper' },
  OverPack: { className: 'home-page feature1', playScale: 0.3 },
  imgWrapper: { className: 'feature1-img', md: 16, xs: 24 },
  img: {
    children: 'assets/datamanager.jpeg',
  },
  textWrapper: { className: 'feature1-text', md: 6, xs: 24 },
  title: {
    className: 'feature1-title',
    children: (
      <span>
        <p>数据闭环管理平台</p>
      </span>
    ),
  },
  content: {
    className: 'feature1-content',
    children: (
      <span>
        <span>
          <p>
            应用大模型技术打造数据驱动的AI基础设施平台，实现数据闭环价值最大化
          </p>
        </span>
      </span>
    ),
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>他们正在使用无问数据闭环产品服务加速AI进化</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 1,
    children: [
      {
        name: 'block1',
        className: 'block',
        md: 64,
        xs: 48,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'assets/customer.png',
          },
          content: {
            children: (
              <span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Teams20DataSource = {
  wrapper: { className: 'home-page-wrapper teams2-wrapper' },
  page: { className: 'home-page teams2' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>关于无问</p>
          </span>
        ),
      },
      {
        name: 'title~lw4l9vvqrdm',
        className: 'lw4l9ybwsv-editor_css',
        children: (
          <dl>
            <dt>公司介绍</dt>
            <dd>
              北京无问智行科技有限公司（下称公司或无问智科）成立于2022年，
              公司致力于提供专业的AI数据和仿真验证一体化解决方案，打造AI数据基础设施和加速器。我们的创始团队主要来自百度Apollo，核心骨干团队汇聚了众多AI领域和仿真领域的精英人才，通过不断探索前沿技术和创新，致力于将先进的技术能力赋能智能驾驶。我们应用大模型技术，为智能驾驶系统的迭代和升级提供准确、高效且低成本的AI数据标注和数据合成能力。应用AI驱动的大模型仿真技术，为智能驾驶汽车上路前提供高可靠、高一致性的仿真测试验证能力，为智慧交通保驾护航。我们坚信，智能驾驶将是未来交通的护航者，而AI数据和仿真验证正是实现智能驾驶大规模应用的两翼。无问智科的使命就是以技术创新的羽翼和专业的服务，为智能驾驶产业的翱翔提供坚实的推力。
              我们定位：无问智科( WUWEN.AI
              )是一家专注于AI数据与仿真验证一体化解决方案的科技公司，公司致力于在为行业内客户提供专业产品与服务，赋能客户价值创造。<br />
            </dd>
            <dt>企业文化</dt>
            <dd>客户至上、专业领先、创新驱动、共创共赢<br/>以终为始、持续小赢、持续成长</dd>
            <dt>创始团队</dt>
            <dd>
              无问智科拥有一流的核心技术团队，创始成员来自百度Apollo和清华大学。我们具有专业的自动驾驶研发测试经验以及丰富的智能驾驶行业资源。创始团队用最佳实践为行业打造最专业的数据+仿真验证产品与服务<br />
            </dd>
          </dl>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    gutter: 72,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 12,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'assets/andy.jpeg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>刘盛翔</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <span>
                    <p>CEO</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <div>
                  <p>
                  前百度自动驾驶测试负责人，智能汽车测试负责人，百度自动驾驶团队前20员工
                  </p>
                  <p>
                  前百度自动驾驶数据、测试与验证团队创始人，全栈研发数据与测试工具链，制定行业多项自动驾驶标准规范
                  </p>
                  <p>
                    中国最早一批自动驾驶技术人员，10年自动驾驶技术及管理经验，具备技术研发、量产验证等全链路自动驾驶经验
                  </p>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 12,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'assets/mark.jpeg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <span>
                    <p>于春磊</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <p>CTO</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>
                    前百度自动驾驶感知技术资深工程师，深度学习领域专家，自动驾驶技术专家
                  </p>
                  <p>
                    前超星未来技术合伙人，10年自动驾驶技术研发经验，丰富的生成式AI研发经验
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 12,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'assets/ken.jpeg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>贺敏</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <p>运营负责人</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <span>
                    <p>
                      12年公司运营管理经验，熟悉公司治理与管理，具有丰富的公司管理、成本控制、预算、财务管理经验
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 12,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'assets/richard.jpeg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <span>
                    <p>朱建新</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <p>首席架构师</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>
                    资深技术专家，10多年技术研发经验，具有丰富的搜索引擎和大数据架构研发经验
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <span>
                <p>联系我们</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'block2',
              className: 'block-address',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      <span>公司地址：</span>
                                      <br />
                                    </p>
                                    <p>
                                      <span>（北京）：</span>
                                      <span>
                                        北京市海淀区西北旺地区丰秀东路中关村壹号A2座601
                                      </span>
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      <span>（德清）：浙江省湖州市</span>
                                      <span>
                                        德清县云岫南路611号车联网产业大厦11楼
                                      </span>
                                      <br />
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'block4',
              className: 'block-contact',
              children: (
                <span>
                  <span>
                    联系方式：
                    <span>
                      <p>商务合作：wuwen_bd@wuwen-ai.com</p>
                    </span>
                    <span>
                      <p>简历投递：wuwen_hr@wuwen-ai.com</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper', id: 'copyright' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <p>
        <span>©2024 Wuwen&nbsp;All Rights Reserved&nbsp;</span>
        <span>&nbsp;
          <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802044490'>京公网安备11010802044490号</a>&nbsp;
          <a href='https://beian.miit.gov.cn'>京ICP备2024051918号</a>
        </span>
      </p>
    ),
  },
};
